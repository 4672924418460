import { AltLayout } from "layouts/AltLayout";
import { AboutPageContainer } from "./about";
import AboutSection from "./components/about-section";
import { DoctorData } from "constants/doctor-intro";
import DoctorIntro from "@/common/doctor-intro";
import { theme } from "@/GlobalStyles";
import AlternativeHeroBanner from "@/common/alternative-hero-banner";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";

const AboutPage = () => {
  return (
    <AboutPageContainer>
      <AltLayout>
        <AlternativeHeroBanner
          PageName="About Us"
          NavigateFrom="Home"
          NavigateTo="About Us"
          IntroHeader="Hello There."
          BgHero="about-us-hero-1.jpg"
          introPara="Dr. Sam and Dr. Blerta are committed to providing personalized and
          compassionate care to create healthy and happy smiles!"
        ></AlternativeHeroBanner>
        <AboutSection />
        <StaticImage
          src="../../images/about-us-banner-2.jpg"
          alt="flowers"
          layout="fullWidth"
          css={css`
            max-height: 420px;
            display: none;
            width: 100%;

            @media (min-width: 992px) {
              display: inline-block;
            }
          `}
        />
        {DoctorData.map((item, ind) => (
          <DoctorIntro
            key={ind}
            image={item.image}
            aapdlogo={item.dlogo}
            drName={item.drName}
            drPost={item.drPost}
            certifications={item.certifications}
            quote={item.quote}
            description={item.description}
            buttonTitle={item.buttonTitle}
            crown={item.crown}
            flexDirection={item.flexDirection}
            backgroundColor={theme.colors.white}
            link={item.link}
            animation={item.animation}
          />
        ))}
        {/* <Gallery /> */}
      </AltLayout>
    </AboutPageContainer>
  );
};

export default AboutPage;
