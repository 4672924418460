import ArrowButton from "@/common/arrow-button";
import GoldenBack from "@/common/golden-back";
import Image from "@/common/image";
import { theme } from "@/GlobalStyles";
import { Heading, Paragraph } from "styles/typography";
import {
  HeadingWrapper,
  ImageWrapper,
  IntroContentWrapper,
  IntroContent,
  AboutSectionMain,
} from "./about-section";

const AboutSection = () => {
  return (
    <AboutSectionMain>
      <IntroContentWrapper>
        <IntroContent>
          <HeadingWrapper>
            <Heading z-index="2">
              PEARL PEDIATRIC DENTISTRY & ORTHODONTICS
            </Heading>
            <GoldenBack left="67.5%" zIndex="-1" />
          </HeadingWrapper>
          <Paragraph>
            As a dual specialty office we strive to provide the best pediatric
            dental and orthodontic care and make visits convenient and FUN! We
            have created a modern environment that both kids and parents can
            enjoy. Dr. Sam and Dr. Blerta are committed to providing
            personalized and compassionate care to create healthy and happy
            smiles!
          </Paragraph>
          <a href="#contact">
            <ArrowButton
              title="Book An Appointment"
              titleColor={theme.colors.darkGolden}
              gradient
              border={`2px solid ${theme.colors.darkGolden}`}
              direction="row-reverse"
              rotate="rotate(45deg)"
              mobileDirection="row-reverse"
            />
          </a>
        </IntroContent>
      </IntroContentWrapper>
      <ImageWrapper data-aos="fade-right">
        <Image
          maxHeight="500px"
          filename="about-us-article.jpg"
          alt="Introduction image"
        />
      </ImageWrapper>
    </AboutSectionMain>
  );
};

export default AboutSection;
