import { theme } from "@/GlobalStyles";
import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const AboutSectionMain = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 150px;

  ${mq["lg"]} {
    margin-bottom: 00px;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const ImageWrapper = styled.div`
  width: 50%;

  > div {
    max-height: 380px;
  }

  @media (min-width: 560px) {
    > div {
      max-height: 480px;
    }
  }

  @media (min-width: 768px) {
    > div {
      max-height: 580px;
    }
  }

  @media (min-width: 992px) {
    > div {
      max-height: 680px;
    }
  }

  @media (min-width: 1600px) {
    > div {
      max-height: 780px;
    }
  }

  ${mq["lg"]} {
    width: 100%;
  }
`;

export const HeadingWrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const IntroContentWrapper = styled.div`
  width: 50%;
  background-color: ${theme.colors.offWhite};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: -100px;
  padding: 62px;
  margin-right: -60px;
  z-index: 1;

  ${mq["xl"]} {
    padding: 15px;
  }

  ${mq["lg"]} {
    margin-bottom: 0px;
    justify-content: center;
    width: 100%;
    padding: 0px;
    margin-right: 0px;
  }
`;

export const IntroContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 20px 10px;

  ${mq["md"]} {
    margin-top: 20px;
  }
`;
